var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "basic-info-p" },
    [
      _c(
        "div",
        {
          staticClass: "basic-info-avator",
          on: {
            click: function ($event) {
              _vm.$refs.cbavater.hasAvator
                ? (_vm.imgDetail = true)
                : (_vm.imgDetail = false)
            },
          },
        },
        [
          _c("CbAvater", {
            ref: "cbavater",
            attrs: {
              "on-job-id": _vm.userInfo.onJobId,
              "avater-name": _vm.userInfo.staffName,
              "show-user-name": false,
              size: "52",
            },
          }),
        ],
        1
      ),
      _c("div", { staticClass: "basic-info-contain" }, [
        _c("div", { staticClass: "info-p" }, [
          _c("div", { staticClass: "name" }, [
            _c("span", [_vm._v(_vm._s(_vm.userInfo.staffName))]),
            _c("span", [_vm._v(_vm._s(_vm.userInfo.staffNum))]),
          ]),
          _c("div", { staticClass: "tel" }, [
            _vm._v(" " + _vm._s(_vm.userInfo.phoneNum) + " "),
          ]),
        ]),
        _c("div", { staticClass: "dept-p" }, [
          _c("div", { staticClass: "com-info" }, [
            _c("div", { staticClass: "com-info-title-post" }, [
              _vm._v("部门："),
            ]),
            _c("div", { staticClass: "com-info-value-post" }, [
              _vm._v(_vm._s(_vm.userInfo.orgName)),
            ]),
          ]),
        ]),
        _c("div", { staticClass: "post-p" }, [
          _c("div", { staticClass: "com-info" }, [
            _c("div", { staticClass: "com-info-text" }, [_vm._v("岗位：")]),
            _c("div", { staticClass: "com-info-val" }, [
              _vm._v(_vm._s(_vm.userInfo.postName)),
            ]),
          ]),
          _c("div", { staticClass: "com-info" }, [
            _c("div", { staticClass: "com-info-title" }, [_vm._v("司龄：")]),
            _c("div", { staticClass: "com-info-value" }, [
              _vm._v(_vm._s(_vm.userInfo.companyAge)),
            ]),
          ]),
        ]),
        _c("div", { staticClass: "addr-p" }, [
          _c("div", { staticClass: "com-info" }, [
            _c("div", { staticClass: "com-info-text" }, [_vm._v("汇报上级：")]),
            _c("div", { staticClass: "com-info-val" }, [
              _vm._v(_vm._s(_vm.userInfo.reportText)),
            ]),
          ]),
          _c("div", { staticClass: "com-info" }, [
            _c("div", { staticClass: "com-info-title" }, [
              _vm._v("工作地点："),
            ]),
            _c("div", { staticClass: "com-info-value" }, [
              _vm._v(" " + _vm._s(_vm.userInfo.workAddr) + " "),
            ]),
          ]),
        ]),
      ]),
      _c(
        "a-modal",
        {
          staticClass: "imgcontainer",
          attrs: {
            title: "放大查看",
            visible: _vm.imgDetail,
            "mask-closable": true,
            footer: "",
          },
          on: { cancel: _vm.cancel },
        },
        [
          _c("img", {
            attrs: {
              src:
                _vm.basePath +
                "/hrsaas-base/staff/getAvatar?onJobId=" +
                _vm.userInfo.onJobId +
                "&width=" +
                400 +
                "&height=" +
                400,
              alt: "",
            },
          }),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }