var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "timeLine" },
    _vm._l(_vm.timeNodeList, function (item, timeRowIndex) {
      return _c(
        "div",
        {
          key: "timeRow" + timeRowIndex,
          staticClass: "timeRow",
          class: timeRowIndex % 2 === 0 ? "even" : "odd",
        },
        _vm._l(item, function (key, index) {
          return _c(
            "div",
            { key: "timeNode" + index },
            [
              _c("timeNode", {
                class: {
                  timeNodeAfter:
                    timeRowIndex !== _vm.timeNodeList.length - 1 &&
                    timeRowIndex % 2 === 0 &&
                    index === item.length - 1,
                  timeNodeAfterBefore:
                    timeRowIndex !== _vm.timeNodeList.length - 1 &&
                    timeRowIndex % 2 !== 0 &&
                    index === item.length - 1,
                },
                attrs: {
                  data: key,
                  "row-index": timeRowIndex,
                  "is-start": index === 0,
                  "is-last": index === item.length - 1,
                  "is-last-row": timeRowIndex === _vm.timeNodeList.length - 1,
                  "singular-last":
                    timeRowIndex % 2 !== 0 && index === _vm.rowShowNum - 1,
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _vm._t("timeNodeItem", null, { row: scope.row }),
                        ]
                      },
                    },
                  ],
                  null,
                  true
                ),
              }),
            ],
            1
          )
        }),
        0
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }