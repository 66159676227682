var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "upload-image" },
    [
      _c(
        "a-upload",
        {
          staticClass: "avatar-uploader",
          attrs: {
            name: "avatar",
            "list-type": "picture-card",
            disabled: _vm.disabled,
            "show-upload-list": _vm.maxSum > 1 ? true : false,
            "before-upload": _vm.beforeUpload,
            "file-list": _vm.fileList,
            multiple: true,
            "custom-request": _vm.customRequest,
            remove: _vm.handleSingleDel,
          },
          on: { change: _vm.handleChange, preview: _vm.previewImg },
        },
        [
          _vm.fileList.length < _vm.maxSum
            ? _c(
                "div",
                { staticClass: "flex-center" },
                [
                  _c("a-icon", {
                    style: { fontSize: "16px", marginRight: "5px" },
                    attrs: { type: _vm.loading ? "loading" : "upload" },
                  }),
                  _c("div", { staticClass: "ant-upload-text" }, [
                    _vm._v(" " + _vm._s(_vm.uplaodTip) + " "),
                  ]),
                ],
                1
              )
            : _c("div", { staticClass: "upload-file-contain" }, [
                _vm.defaultIcon
                  ? _c("img", {
                      staticClass: "upload-file",
                      attrs: { src: _vm.defaultIcon },
                    })
                  : _vm._e(),
                _vm.imageIcon
                  ? _c("img", {
                      staticClass: "upload-file-image",
                      attrs: { src: _vm.imageIcon },
                    })
                  : _vm._e(),
                _vm.defaultIcon
                  ? _c("div", { staticClass: "upload-title" }, [
                      _vm._v(_vm._s(_vm.fileList[0].originFileName)),
                    ])
                  : _vm._e(),
                _c("span", { staticClass: "upload-file-actions-p" }, [
                  _c(
                    "div",
                    { staticClass: "upload-file-actions" },
                    [
                      ["jpg", "jpeg", "png"].includes(
                        _vm.fileList[0].name.toLowerCase().split(".")[1]
                      )
                        ? _c("a-icon", {
                            attrs: { type: "eye" },
                            on: {
                              click: function ($event) {
                                $event.stopPropagation()
                                return _vm.previewImg(_vm.fileList[0].url)
                              },
                            },
                          })
                        : _vm._e(),
                      _c("a-icon", {
                        attrs: { type: "download" },
                        on: {
                          click: function ($event) {
                            $event.stopPropagation()
                            return _vm.handleDownload(_vm.fileList[0])
                          },
                        },
                      }),
                      _vm.showDel
                        ? _c("a-icon", {
                            attrs: { type: "delete" },
                            on: {
                              click: function ($event) {
                                $event.stopPropagation()
                                return _vm.handleSingleDel(_vm.fileList[0])
                              },
                            },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                ]),
              ]),
        ]
      ),
      _c(
        "Modal",
        {
          attrs: {
            visible: _vm.previewVisible,
            closable: true,
            "overflow-auto": "",
            footer: "",
            title: "查看大图",
            size: "normal",
            "mask-closable": false,
          },
          on: {
            cancel: function ($event) {
              _vm.previewVisible = false
            },
          },
        },
        [
          _c("img", {
            staticStyle: { width: "100%" },
            attrs: { alt: "example", src: _vm.previewImage },
          }),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }