var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "person-info-p" }, [
    _c(
      "div",
      { staticClass: "person-anchor" },
      [
        _vm.showAnchor
          ? _c("Anchor", {
              ref: "anchor",
              style: { height: _vm.anchorHeight() },
              attrs: {
                "title-list": _vm.anchorList,
                container: ".person-info",
              },
            })
          : _vm._e(),
      ],
      1
    ),
    _c(
      "div",
      {
        ref: "personInfo",
        staticClass: "person-info beauty-scroll",
        style: { height: _vm.anchorHeight() },
      },
      [
        _c(
          "div",
          { staticClass: "person-info-download-p" },
          [
            _vm._l(_vm.infoList, function (item, index) {
              return [
                (!item.multiple && item.content.list[0].columns.length > 0) ||
                item.multiple
                  ? _c("div", { key: index, staticClass: "com-panel" }, [
                      _c(
                        "div",
                        {
                          staticClass: "com-panel-head",
                          attrs: { id: "grid_" + item.fieldGroupId },
                        },
                        [
                          _c(
                            "BlockTitle",
                            { attrs: { title: item.fieldGroupName } },
                            [
                              _c("template", { slot: "center" }, [
                                _c("div", { staticClass: "com-head-divide" }),
                              ]),
                              _c("template", { slot: "right" }, [
                                !item.isEdit &&
                                item.multiple == 0 &&
                                _vm.statusType !== "toExamine" &&
                                _vm.statusType !== "resigned"
                                  ? _c(
                                      "div",
                                      { staticClass: "com-head-edit" },
                                      [
                                        _c(
                                          "a-button",
                                          {
                                            attrs: { type: "link" },
                                            on: {
                                              click: function ($event) {
                                                return _vm.openEdit(item)
                                              },
                                            },
                                          },
                                          [_vm._v("编辑")]
                                        ),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                              ]),
                            ],
                            2
                          ),
                        ],
                        1
                      ),
                      !item.multiple
                        ? _c("div", { staticClass: "com-panel-body" }, [
                            !item.isEdit
                              ? _c(
                                  "div",
                                  { staticClass: "com-body-noEdit" },
                                  [
                                    _vm._l(
                                      item.content.list[0].columns,
                                      function (obj, objIndex) {
                                        return [
                                          obj.list[0].show
                                            ? _c(
                                                "div",
                                                {
                                                  key: objIndex,
                                                  staticClass: "com-body-item",
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "com-body-item-label",
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          obj.list[0].fieldName
                                                        ) + " "
                                                      ),
                                                      _c(
                                                        "a-tooltip",
                                                        {
                                                          attrs: {
                                                            placement: "right",
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "template",
                                                            { slot: "title" },
                                                            [
                                                              _vm._v(
                                                                " 此试用期为计划试用期 "
                                                              ),
                                                            ]
                                                          ),
                                                          obj.list[0]
                                                            .fieldCode ===
                                                          "probation"
                                                            ? _c("a-icon", {
                                                                staticClass:
                                                                  "tips_icon",
                                                                attrs: {
                                                                  type: "exclamation-circle",
                                                                  theme:
                                                                    "filled",
                                                                },
                                                              })
                                                            : _vm._e(),
                                                        ],
                                                        2
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                  obj.list[0].fieldCode ===
                                                  "probation"
                                                    ? _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "com-body-item-value",
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              obj.list[0]
                                                                .fieldText
                                                                ? obj.list[0]
                                                                    .fieldText +
                                                                    "个月"
                                                                : ""
                                                            )
                                                          ),
                                                        ]
                                                      )
                                                    : obj.list[0].fieldCode ===
                                                        "nativeplace" ||
                                                      obj.list[0].fieldCode ===
                                                        "address"
                                                    ? _c(
                                                        "div",
                                                        [
                                                          _c(
                                                            "a-tooltip",
                                                            {
                                                              attrs: {
                                                                placement:
                                                                  "bottom",
                                                              },
                                                            },
                                                            [
                                                              _c(
                                                                "template",
                                                                {
                                                                  slot: "title",
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    " " +
                                                                      _vm._s(
                                                                        obj
                                                                          .list[0]
                                                                          .fieldText
                                                                      ) +
                                                                      " "
                                                                  ),
                                                                ]
                                                              ),
                                                              _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "com-body-item-value",
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      obj
                                                                        .list[0]
                                                                        .fieldText
                                                                    )
                                                                  ),
                                                                ]
                                                              ),
                                                            ],
                                                            2
                                                          ),
                                                        ],
                                                        1
                                                      )
                                                    : _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "com-body-item-value",
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              obj.list[0]
                                                                .fieldText
                                                            )
                                                          ),
                                                        ]
                                                      ),
                                                  !!obj.list[0].modify
                                                    ? _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "com-body-item-update",
                                                        },
                                                        [_vm._v("已更新")]
                                                      )
                                                    : _vm._e(),
                                                ]
                                              )
                                            : _vm._e(),
                                        ]
                                      }
                                    ),
                                  ],
                                  2
                                )
                              : _c(
                                  "div",
                                  { staticClass: "com-body-edit" },
                                  [
                                    _c("k-form-build", {
                                      ref: "KFormBuild" + index,
                                      refInFor: true,
                                      attrs: {
                                        "engage-index": index,
                                        value: item.content || {},
                                        "dynamic-data": _vm.dynamicData,
                                        "relation-key-obj": _vm.relationKeyObj,
                                        "org-id": _vm.orgId,
                                        "post-id": _vm.postId,
                                        "has-relation":
                                          item.fieldGroupName === "在职信息",
                                      },
                                      on: { change: _vm.changehand },
                                    }),
                                    _c(
                                      "div",
                                      { staticClass: "com-body-edit-btns" },
                                      [
                                        _c(
                                          "a-button",
                                          {
                                            attrs: { type: "primary" },
                                            on: {
                                              click: function ($event) {
                                                return _vm.saveForm(
                                                  item,
                                                  item.multiple,
                                                  index,
                                                  item.fieldGroupId
                                                )
                                              },
                                            },
                                          },
                                          [_vm._v(" 保存 ")]
                                        ),
                                        _c(
                                          "a-button",
                                          {
                                            on: {
                                              click: function ($event) {
                                                return _vm.cancelForm(item)
                                              },
                                            },
                                          },
                                          [_vm._v("取消")]
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                          ])
                        : _c(
                            "div",
                            { staticClass: "com-panel-multi-body" },
                            [
                              _vm._l(item.content, function (item1, itemIndex) {
                                return _c(
                                  "div",
                                  {
                                    key: itemIndex,
                                    staticClass: "com-panel-multi-body-p",
                                  },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "com-body-title" },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass: "com-body-title-name",
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(item.fieldGroupName) +
                                                _vm._s(itemIndex + 1) +
                                                " "
                                            ),
                                            item1.modify === 1
                                              ? _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "com-body-item-update",
                                                  },
                                                  [_vm._v("已新增")]
                                                )
                                              : _vm._e(),
                                            item1.modify === 2
                                              ? _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "com-body-item-update",
                                                  },
                                                  [_vm._v("已删除")]
                                                )
                                              : _vm._e(),
                                          ]
                                        ),
                                        _c(
                                          "div",
                                          {
                                            staticClass: "com-body-title-btns",
                                          },
                                          [
                                            !item1.isEdit &&
                                            _vm.statusType !== "toExamine" &&
                                            _vm.statusType !== "resigned"
                                              ? _c(
                                                  "a-button",
                                                  {
                                                    attrs: { type: "link" },
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.openEdit(
                                                          item1,
                                                          index,
                                                          itemIndex
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [_vm._v(" 编辑 ")]
                                                )
                                              : _vm._e(),
                                            _vm.dataType(item1.dataId) !==
                                              "Null" &&
                                            !item1.isEdit &&
                                            _vm.statusType !== "toExamine" &&
                                            _vm.statusType !== "resigned"
                                              ? _c(
                                                  "a-button",
                                                  {
                                                    staticClass: "delete",
                                                    attrs: { type: "link" },
                                                    on: {
                                                      click: function ($event) {
                                                        _vm.mulitDelHandle(
                                                          item1,
                                                          item.fieldGroupName +
                                                            (itemIndex + 1),
                                                          index,
                                                          itemIndex
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [_vm._v("删除")]
                                                )
                                              : _vm._e(),
                                          ],
                                          1
                                        ),
                                      ]
                                    ),
                                    !item1.isEdit
                                      ? _c(
                                          "div",
                                          { staticClass: "com-body-noEdit" },
                                          _vm._l(
                                            item1.content.list[0].columns,
                                            function (obj, objIndex) {
                                              return _c(
                                                "div",
                                                {
                                                  key: objIndex,
                                                  staticClass: "com-body-item",
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "com-body-item-label",
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          obj.list[0].fieldName
                                                        )
                                                      ),
                                                    ]
                                                  ),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "com-body-item-value",
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          obj.list[0].fieldText
                                                        )
                                                      ),
                                                    ]
                                                  ),
                                                  !!obj.list[0].modify
                                                    ? _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "com-body-item-update",
                                                        },
                                                        [_vm._v("已更新")]
                                                      )
                                                    : _vm._e(),
                                                ]
                                              )
                                            }
                                          ),
                                          0
                                        )
                                      : _c(
                                          "div",
                                          { staticClass: "com-body-edit" },
                                          [
                                            _c("k-form-build", {
                                              ref:
                                                "KFormBuild" +
                                                index +
                                                "Multi" +
                                                itemIndex,
                                              refInFor: true,
                                              attrs: {
                                                value: item1.content || {},
                                                "dynamic-data": _vm.dynamicData,
                                              },
                                              on: { change: _vm.changehand },
                                            }),
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "com-body-edit-btns1",
                                              },
                                              [
                                                _c(
                                                  "a-button",
                                                  {
                                                    attrs: { type: "primary" },
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.saveForm(
                                                          item1,
                                                          item.multiple,
                                                          index +
                                                            "Multi" +
                                                            itemIndex,
                                                          item.fieldGroupId,
                                                          item1.dataId
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [_vm._v(" 保存 ")]
                                                ),
                                                _c(
                                                  "a-button",
                                                  {
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.cancelForm(
                                                          item1,
                                                          index,
                                                          itemIndex
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [_vm._v("取消")]
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                  ]
                                )
                              }),
                              _vm.statusType !== "toExamine" &&
                              _vm.statusType !== "resigned"
                                ? _c(
                                    "div",
                                    {
                                      staticClass: "com-body-add",
                                      on: {
                                        click: function ($event) {
                                          return _vm.addMultiForm(
                                            index,
                                            item.content
                                          )
                                        },
                                      },
                                    },
                                    [_vm._v("新增")]
                                  )
                                : _vm._e(),
                            ],
                            2
                          ),
                    ])
                  : _vm._e(),
              ]
            }),
          ],
          2
        ),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }