var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "component-userinfo-contain" },
    [
      _c("div", { staticClass: "userinfo-contain" }, [
        _c(
          "div",
          { staticClass: "basic-userinfo-p" },
          [
            _c(
              "basicInfo",
              _vm._b(
                {
                  attrs: {
                    update: _vm.update,
                    "status-type": _vm.statusType,
                    "on-job-id": _vm.onJobId,
                  },
                },
                "basicInfo",
                _vm.$props,
                false
              )
            ),
          ],
          1
        ),
        _c("div", { staticClass: "tab-userinfo-p" }, [
          _c(
            "div",
            {
              staticClass: "com-tab",
              class: { active: _vm.activeTab == "personal" },
              on: {
                click: function ($event) {
                  return _vm.changeTab("personal")
                },
              },
            },
            [_vm._v(" 个人信息 ")]
          ),
          _c(
            "div",
            {
              staticClass: "com-tab",
              class: { active: _vm.activeTab == "attachment" },
              on: {
                click: function ($event) {
                  return _vm.changeTab("attachment")
                },
              },
            },
            [_vm._v(" 附件材料 ")]
          ),
          _vm.statusType !== "toExamine"
            ? _c(
                "div",
                {
                  staticClass: "com-tab",
                  class: { active: _vm.activeTab == "record" },
                  on: {
                    click: function ($event) {
                      return _vm.changeTab("record")
                    },
                  },
                },
                [_vm._v(" 信息记录 ")]
              )
            : _vm._e(),
        ]),
      ]),
      _c(
        "div",
        { staticClass: "tab-info-p" },
        [
          _vm.activeTab == "personal"
            ? _c(
                "personInfo",
                _vm._b(
                  { on: { updateInfo: _vm.updateInfo } },
                  "personInfo",
                  _vm.$props,
                  false
                )
              )
            : _vm._e(),
          _vm.activeTab == "attachment"
            ? _c(
                "attachmentInfo",
                _vm._b({}, "attachmentInfo", _vm.$props, false)
              )
            : _vm._e(),
          _vm.activeTab == "record"
            ? _c(
                "infoMationRecord",
                _vm._b(
                  { attrs: { "status-type": _vm.statusType } },
                  "infoMationRecord",
                  _vm.$props,
                  false
                )
              )
            : _vm._e(),
        ],
        1
      ),
      _vm.statusType === "toExamine"
        ? _c("examine", {
            staticClass: "examine_class",
            attrs: {
              "on-job-id": _vm.onJobId,
              "page-type": _vm.pageType,
              "archives-perfect-id": _vm.archivesPerfectId,
              "user-id": _vm.userId,
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }