var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "Modal",
    {
      attrs: {
        closable: true,
        visible: _vm.visible,
        title: (_vm.mode == "add" ? "添加" : "编辑") + _vm.name,
        "ok-text": "确认",
        "destroy-on-close": true,
        size: "normal",
      },
      on: { ok: _vm.handleOk, cancel: _vm.handleCancel },
    },
    [
      _c(
        "a-form-model",
        {
          ref: "ruleForm",
          attrs: {
            model: _vm.form,
            rules: _vm.rules,
            "label-col": _vm.labelCol,
            "wrapper-col": _vm.wrapperCol,
          },
        },
        [
          _c(
            "a-form-model-item",
            { attrs: { label: _vm.name + "名称", prop: "fileName" } },
            [
              _c("a-input", {
                attrs: { placeholder: "请输入1-20个字符" },
                model: {
                  value: _vm.form.fileName,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "fileName", $$v)
                  },
                  expression: "form.fileName",
                },
              }),
            ],
            1
          ),
          _vm.mode == "add"
            ? _c(
                "a-form-model-item",
                { attrs: { label: _vm.name + "文件", prop: "fileId" } },
                [
                  _vm.name == "基本材料"
                    ? _c("UploadImage", {
                        attrs: {
                          "max-sum": 1,
                          "type-list": _vm.uploadImageType,
                          "file-size": 10,
                          "model-upload": false,
                        },
                        on: { change: _vm.imageUploadChange },
                      })
                    : _vm._e(),
                  _vm.name == "档案材料"
                    ? _c("UploadFile", {
                        attrs: {
                          "type-list": _vm.uploadFileType,
                          "file-size": 50,
                          "model-upload": false,
                          "max-sum": 1,
                        },
                        on: { change: _vm.imageUploadChange },
                      })
                    : _vm._e(),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
      _vm.mode == "add"
        ? _c("Alert", {
            attrs: { type: "info", "show-icon": "" },
            scopedSlots: _vm._u(
              [
                {
                  key: "content",
                  fn: function () {
                    return [
                      _c("div", [
                        _vm._v(
                          _vm._s(
                            "请上传" +
                              (_vm.name === "基本材料"
                                ? _vm.imageTips
                                : _vm.fileTips) +
                              "类型的文件"
                          )
                        ),
                      ]),
                    ]
                  },
                  proxy: true,
                },
              ],
              null,
              false,
              1535669518
            ),
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }