<template>
  <div class="timeNode">
    <div class="top">
      <!-- v-if="!(isStart && rowIndex === 0) && ((isLast && rowType === 'left') || (isStart && rowType === 'right'))" -->
      <div v-if="hasStartLine" :style="`background-image: linear-gradient(to ${rowType}, ${borderStyle});`" class="startLine" />
      <div class="circle" />
      <div class="time">{{ data.time }}</div>
      <div
        v-if="!(isLastRow && isLast && rowType === 'right')"
        :class="{ 'lastWidth': isLast }"
        :style="`background-image: linear-gradient(to ${rowType}, ${borderStyle});`"
        class="line"
      />
    </div>
    <div class="content">
      <div class="title">{{ data.title }}</div>
      <div class="reason"><slot :row="data" name="default" /></div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TimeNode',
  props: {
    data: {
      type: Object,
      default: () => {}
    },
    isLast: {
      type: Boolean,
      default: false
    },
    isStart: {
      type: Boolean,
      default: true
    },
    isLastRow: {
      type: Boolean,
      default: false
    },
    rowIndex: {
      type: Number,
      default: 0
    },
    singularLast: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {}
  },
  computed: {
    rowType() {
      // right 为偶数  left 为奇数
      return `${this.rowIndex % 2 === 0 ? 'right' : 'left'}`
    },
    borderStyle() {
      if (!this.isLast) return 'rgba(70, 114, 255, 1), rgba(89, 128, 255, 0.2)'
      if (this.isLast && this.rowType === 'right') {
        return 'rgba(114, 147, 255, 1), rgba(107, 142, 255, 1)'
      } else {
        return 'rgba(70, 114, 255, 1), rgba(89, 128, 255, 0.2)'
        // return 'rgba(186, 202, 255, 1), rgba(222, 230, 255, 0.2)'
      }
    },
    hasStartLine() {
      // 偶数行
      if (this.rowType === 'right') {
        if (this.isStart && this.rowIndex !== 0) {
          return true
        }
      }
      // 奇数行
      if (this.rowType === 'left') {
        if (this.singularLast && !this.isLastRow) {
          return true
        }
      }
      return false
    }
  }
}
</script>

<style lang="less" scoped>
.timeNode {
  margin-bottom: 30px;
  position: relative;
}
.lastWidth {
  width: 150px !important;
}
.startLine {
  position: absolute;
  width: 54px;
  border: 2px dashed #fff;
  background-origin: border-box;
  left: -54px;
}
.top {
  display: flex;
  align-items: center;
  .circle {
    width: 22px;
    height: 22px;
    border: 4px solid @sc-primary-100;
    border-radius: 50%;
  }
  .time {
    // width: 92px;
    height: 22px;
    padding: 0 20px 0 20px;
    text-align: center;
    line-height: 22px;
    font-size: 12px;
    color: rgba(32, 42, 64, 0.8);
    background-color: rgba(81, 121, 251, 0.1);
    margin-left: -4px;
  }
  .line {
    width: 140px;
    border: 2px dashed #fff;
    background-origin: border-box;
  }
}
.content {
  margin-top: 10px;
  // margin-left: 20px;
  .title {
    color: rgba(32, 42, 64, 1);
    font-size: 14px;
  }
  .reason {
    color: rgba(32, 42, 64, 0.6);
    width: 240px;
  }
}
</style>
