var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "attachment-info-p" },
    [
      _c(
        "div",
        { staticClass: "attachment-anchor" },
        [
          _vm.showAnchor
            ? _c("Anchor", {
                ref: "anchor",
                attrs: {
                  "title-list": _vm.anchorList,
                  container: ".attachment-info",
                  position: { top: 20, left: 35, position: "absolute" },
                },
              })
            : _vm._e(),
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "attachment-info beauty-scroll",
          style: { height: _vm.anchorHeight() },
        },
        _vm._l(_vm.infoList, function (item, index) {
          return _c("div", { key: index, staticClass: "com-panel" }, [
            _c(
              "div",
              {
                staticClass: "com-panel-head",
                attrs: { id: "grid_" + item.groupId },
              },
              [
                _c(
                  "BlockTitle",
                  { attrs: { title: item.groupName } },
                  [
                    _c("template", { slot: "center" }, [
                      _c("div", { staticClass: "com-head-divide" }),
                    ]),
                  ],
                  2
                ),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "com-panel-body" },
              [
                _vm._l(item.children, function (pic, picIndex) {
                  return _c(
                    "div",
                    { key: picIndex, staticClass: "com-panel-body-item" },
                    [
                      item.groupId == "1"
                        ? _c("UploadImage", {
                            staticClass: "item-upload",
                            attrs: {
                              "prop-file-list": pic.fileId ? [pic] : [],
                              "max-sum": 1,
                              "type-list": ["jpeg", "png", "jpg", "pdf"],
                              "file-size": 10,
                              disabled: _vm.statusTypeDisabled,
                              "show-del":
                                _vm.$route.query.pageType !== "archive" &&
                                _vm.statusType !== "toExamine" &&
                                _vm.statusType !== "resigned",
                            },
                            on: {
                              fileUpload: function (res) {
                                return _vm.uploadImg(res, pic)
                              },
                              fileDel: _vm.delImg,
                            },
                          })
                        : _vm._e(),
                      item.groupId == "2"
                        ? _c("UploadFile", {
                            staticClass: "item-upload",
                            attrs: {
                              "prop-file-list": pic.fileId ? [pic] : [],
                              "max-sum": 1,
                              "type-list": [
                                "doc",
                                "docx",
                                "pdf",
                                "jpeg",
                                "png",
                                "jpg",
                              ],
                              "file-size": 50,
                              disabled: _vm.statusTypeDisabled,
                              "show-del":
                                _vm.$route.query.pageType !== "archive" &&
                                _vm.statusType !== "toExamine" &&
                                _vm.statusType !== "resigned",
                            },
                            on: {
                              fileUpload: function (res) {
                                return _vm.uploadFile(res, pic)
                              },
                              fileDel: _vm.delImg,
                            },
                          })
                        : _vm._e(),
                      _c("div", { staticClass: "item-title" }, [
                        _vm._v(" " + _vm._s(pic.fileName) + " "),
                        pic.createType !== 0
                          ? _c(
                              "div",
                              { staticClass: "flex-align-center" },
                              [
                                _c(
                                  "a-popover",
                                  {
                                    attrs: {
                                      placement: "bottom",
                                      trigger: "hover",
                                    },
                                  },
                                  [
                                    _c("template", { slot: "content" }, [
                                      _c(
                                        "div",
                                        { staticClass: "more-handle-btn" },
                                        [
                                          _c(
                                            "a-button",
                                            {
                                              attrs: { type: "link" },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.edit(pic, item)
                                                },
                                              },
                                            },
                                            [_vm._v(" 编辑 ")]
                                          ),
                                          _c(
                                            "a-button",
                                            {
                                              staticClass: "delete",
                                              attrs: { type: "link" },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.delSingle(pic)
                                                },
                                              },
                                            },
                                            [_vm._v(" 删除 ")]
                                          ),
                                        ],
                                        1
                                      ),
                                    ]),
                                    _vm.statusType !== "toExamine" &&
                                    _vm.statusType !== "resigned"
                                      ? _c(
                                          "div",
                                          { staticClass: "more-handle" },
                                          [_vm._v(" ··· ")]
                                        )
                                      : _vm._e(),
                                  ],
                                  2
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                      ]),
                    ],
                    1
                  )
                }),
                _c(
                  "div",
                  { staticClass: "com-panel-body-item" },
                  [
                    _vm.statusType !== "toExamine" &&
                    _vm.statusType !== "resigned"
                      ? _c(
                          "a-button",
                          {
                            staticClass: "attachment-add-btn",
                            attrs: { prefix: "add-one" },
                            on: {
                              click: function ($event) {
                                return _vm.addAttachment(item)
                              },
                            },
                          },
                          [_vm._v(" 添加" + _vm._s(item.groupName) + " ")]
                        )
                      : _vm._e(),
                  ],
                  1
                ),
              ],
              2
            ),
          ])
        }),
        0
      ),
      _c(
        "ModalForm",
        _vm._b(
          {
            ref: "modalForm",
            attrs: { name: _vm.currentFormName },
            on: {
              reload: function ($event) {
                return _vm.initData()
              },
            },
          },
          "ModalForm",
          _vm.$props,
          false
        )
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }