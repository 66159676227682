import { render, staticRenderFns } from "./person-info.vue?vue&type=template&id=044d9149&scoped=true&"
import script from "./person-info.vue?vue&type=script&lang=js&"
export * from "./person-info.vue?vue&type=script&lang=js&"
import style0 from "./person-info.vue?vue&type=style&index=0&id=044d9149&lang=less&scoped=true&"
import style1 from "./person-info.vue?vue&type=style&index=1&id=044d9149&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "044d9149",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/var/jenkins_home/workspace/HRSAAS-WEB-DBSJ-DEV/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('044d9149')) {
      api.createRecord('044d9149', component.options)
    } else {
      api.reload('044d9149', component.options)
    }
    module.hot.accept("./person-info.vue?vue&type=template&id=044d9149&scoped=true&", function () {
      api.rerender('044d9149', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/pages/user/components/userInfo/person-info.vue"
export default component.exports