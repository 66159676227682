<template>
  <div class="timeLine">
    <div
      v-for="(item, timeRowIndex) in timeNodeList"
      :key="`timeRow${timeRowIndex}`"
      class="timeRow"
      :class="timeRowIndex % 2 === 0 ? 'even' : 'odd'"
    >
      <!-- <div
        :class="timeRowIndex % 2 !== 0 ? 'white' : 'blue'"
        class="transition"
      /> -->
      <div v-for="(key, index) in item" :key="`timeNode${index}`">
        <timeNode
          :data="key"
          :row-index="timeRowIndex"
          :is-start="index === 0"
          :is-last="index === item.length - 1"
          :is-last-row="timeRowIndex === timeNodeList.length - 1"
          :singular-last="(timeRowIndex % 2 !== 0) && (index === rowShowNum - 1)"
          :class="{
            'timeNodeAfter':
              (timeRowIndex !== timeNodeList.length - 1
                && timeRowIndex % 2 === 0
                && index === item.length - 1) ,
            'timeNodeAfterBefore':
              (timeRowIndex !== timeNodeList.length - 1
                && timeRowIndex % 2 !== 0
                && index === item.length - 1)}"
        >
          <template slot-scope="scope">
            <slot name="timeNodeItem" :row="scope.row" />
          </template>
        </timeNode>
      </div>
      <!-- <div
        :class="timeRowIndex % 2 === 0 ? 'blue' : 'white'"
        class="transition"
      /> -->
    </div>
  </div>
</template>

<script>
import timeNode from './timeNode'
export default {
  name: 'TimeLine',
  components: {
    timeNode
  },
  props: {
    data: {
      type: Array,
      default: () => []
    },
    // 单行展示几个节点，根据预留空间自己传入适当的值
    rowShowNum: {
      type: Number,
      default: 4
    }
  },
  data() {
    return {}
  },
  computed: {
    timeNodeList() {
      const list = []
      let item = []
      this.data.forEach((key, index) => {
        item.push(key)
        if ((index + 1) % this.rowShowNum === 0 && index !== 0) {
          list.push(item)
          item = []
        }
        if (
          this.data.length - 1 - index < 6 &&
          this.data.length - 1 === index &&
          item.length > 0
        ) {
          list.push(item)
        }
      })
      return list
    }
  }
}
</script>

<style lang="less" scoped>
.timeLine {
  display: inline-block;
  position: relative;
  padding: 0 70px;
  overflow: hidden;
}
.timeNodeAfterBefore::before {
  content: '';
  position: absolute;
  height: calc(100% + 30px);
  border: 2px dashed #fff;
  background: linear-gradient(to bottom, rgba(107, 142, 255, 1), rgba(186, 202, 255, 1));
  background-origin: border-box;
  position: absolute;
  left: -54px;
  top: 12px;
}
.timeNodeAfter::after {
  content: '';
  position: absolute;
  top: 10px;
  right: 0;
  height: calc(100% + 30px);
  border: 2px dashed #fff;
  background: linear-gradient(to bottom, rgba(107, 142, 255, 1), rgba(186, 202, 255, 1));
  background-origin: border-box;
}
.timeRow {
  display: flex;
  flex-wrap: wrap;
  position: relative;
  width: 100%;
}
.odd{
  flex-direction: row-reverse;
}
.normal {
  margin-right: 20px;
}
.isLast {
  margin-right: 0;
}
</style>
