var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "box" },
    [
      _c(
        "a-button",
        {
          staticClass: "box_margin",
          on: {
            click: function ($event) {
              return _vm.cancel("no")
            },
          },
        },
        [_vm._v("审核驳回,通知TA修改")]
      ),
      _c(
        "a-button",
        {
          attrs: { type: "primary" },
          on: {
            click: function ($event) {
              return _vm.confirm("yes")
            },
          },
        },
        [_vm._v("确认无误,更新员工信息")]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }