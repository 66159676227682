var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "infomation-record-p" }, [
    _c(
      "div",
      { staticClass: "infomation-anchor" },
      [
        _vm.showAnchor
          ? _c("Anchor", {
              ref: "anchor",
              attrs: {
                "title-list": _vm.anchorList,
                container: ".infomation-record",
                position: { top: 20, left: 35, position: "absolute" },
              },
            })
          : _vm._e(),
      ],
      1
    ),
    _c(
      "div",
      {
        ref: "personInfo",
        staticClass: "infomation-record beauty-scroll",
        style: { height: _vm.anchorHeight() },
      },
      _vm._l(_vm.infoList, function (item, index) {
        return _c("div", { key: index, staticClass: "com-panel" }, [
          _c(
            "div",
            {
              staticClass: "com-panel-head",
              attrs: { id: "grid_" + item.fieldGroupId },
            },
            [
              _c(
                "BlockTitle",
                { attrs: { title: item.fieldGroupName } },
                [
                  _c("template", { slot: "center" }, [
                    _c("div", { staticClass: "com-head-divide" }),
                  ]),
                  _c("template", { slot: "right" }, [
                    !item.isEdit &&
                    item.multiple == 0 &&
                    _vm.statusType !== "resigned"
                      ? _c(
                          "div",
                          { staticClass: "com-head-edit" },
                          [
                            _c(
                              "a-button",
                              {
                                attrs: { type: "link" },
                                on: {
                                  click: function ($event) {
                                    return _vm.openEdit(item)
                                  },
                                },
                              },
                              [_vm._v("编辑")]
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                  ]),
                ],
                2
              ),
            ],
            1
          ),
          _c(
            "div",
            [
              _c(
                "div",
                { staticClass: "noDataImg_box" },
                [
                  item.fieldGroupId == 1 && _vm.staffGrowthList.length === 0
                    ? _c("NoData")
                    : _vm._e(),
                  item.fieldGroupId == 2 && _vm.staffTrainList.length === 0
                    ? _c("NoData")
                    : _vm._e(),
                  item.fieldGroupId == 3 && _vm.staffContractList.length === 0
                    ? _c("NoData")
                    : _vm._e(),
                ],
                1
              ),
              item.fieldGroupId == 1
                ? _c("CbTimeLine", {
                    attrs: { data: _vm.staffGrowthList },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "timeNodeItem",
                          fn: function (scope) {
                            return _c(
                              "div",
                              {},
                              _vm._l(
                                scope.row.reason.split("*****"),
                                function (itemm, indexx) {
                                  return _c("p", { key: "growth" + indexx }, [
                                    _vm._v(" " + _vm._s(itemm || "") + " "),
                                  ])
                                }
                              ),
                              0
                            )
                          },
                        },
                      ],
                      null,
                      true
                    ),
                  })
                : _vm._e(),
              item.fieldGroupId == 2
                ? _c("CbTimeLine", {
                    attrs: { data: _vm.staffTrainList },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "timeNodeItem",
                          fn: function (scope) {
                            return _c("div", {}, [
                              _vm.staffTrainList.length > 0
                                ? _c("div", [
                                    _c("p", [
                                      _vm._v(
                                        "培训单位：" +
                                          _vm._s(scope.row.trainAgency || "")
                                      ),
                                    ]),
                                    _c("p", [
                                      _vm._v(
                                        "证书名称：" +
                                          _vm._s(
                                            scope.row.certificateName || ""
                                          )
                                      ),
                                    ]),
                                    _c("p", [
                                      _vm._v(
                                        "证书编号：" +
                                          _vm._s(
                                            scope.row.certificateCode || ""
                                          )
                                      ),
                                    ]),
                                    _c("p", [
                                      _vm._v(
                                        "培训时长：" +
                                          _vm._s(
                                            scope.row.trainTime === "暂无数据"
                                              ? "暂无数据"
                                              : scope.row.trainTime + "小时" ||
                                                  ""
                                          )
                                      ),
                                    ]),
                                  ])
                                : _vm._e(),
                            ])
                          },
                        },
                      ],
                      null,
                      true
                    ),
                  })
                : _vm._e(),
              item.fieldGroupId == 3
                ? _c("CbTimeLine", {
                    attrs: { data: _vm.staffContractList },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "timeNodeItem",
                          fn: function (scope) {
                            return _c("div", {}, [
                              _vm.staffContractList.length > 0
                                ? _c("div", [
                                    _c("p", [
                                      _vm._v(
                                        _vm._s(
                                          scope.row.contractTerm === "暂无数据"
                                            ? "合同期限" +
                                                scope.row.contractTerm
                                            : "合同期限：" +
                                                scope.row.contractTerm
                                        ) +
                                          " | " +
                                          _vm._s(
                                            scope.row.signDate === "暂无数据"
                                              ? "签订日期" + scope.row.signDate
                                              : scope.row.signDate + "签订"
                                          )
                                      ),
                                    ]),
                                    _c("p", [
                                      _vm._v(
                                        "合同类型：" +
                                          _vm._s(
                                            scope.row.contractTypeName || ""
                                          )
                                      ),
                                    ]),
                                    _c("p", [
                                      _vm._v(
                                        "合同状态：" +
                                          _vm._s(
                                            scope.row.contractStatusName || ""
                                          )
                                      ),
                                    ]),
                                    _c("p", [
                                      _vm._v(
                                        "合同编号：" +
                                          _vm._s(scope.row.contractCode || "")
                                      ),
                                    ]),
                                  ])
                                : _vm._e(),
                            ])
                          },
                        },
                      ],
                      null,
                      true
                    ),
                  })
                : _vm._e(),
            ],
            1
          ),
        ])
      }),
      0
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }