<template>
  <div class="upload-image">
    <a-upload
      name="avatar"
      list-type="picture-card"
      class="avatar-uploader"
      :disabled="disabled"
      :show-upload-list="maxSum > 1 ? true : false"
      :before-upload="beforeUpload"
      :file-list="fileList"
      :multiple="true"
      :custom-request="customRequest"
      :remove="handleSingleDel"
      @change="handleChange"
      @preview="previewImg"
    >
      <!-- <img v-if="imageUrl" :src="imageUrl" alt="avatar"> -->
      <div v-if="fileList.length < maxSum" class="flex-center">
        <a-icon
          :type="loading ? 'loading' : 'upload'"
          :style="{ fontSize: '16px', marginRight: '5px' }"
        />
        <div class="ant-upload-text">
          {{ uplaodTip }}
        </div>
      </div>
      <div v-else class="upload-file-contain">
        <img v-if="defaultIcon" class="upload-file" :src="defaultIcon">
        <img v-if="imageIcon" class="upload-file-image" :src="imageIcon">
        <div v-if="defaultIcon" class="upload-title">{{ fileList[0].originFileName }}</div>
        <span class="upload-file-actions-p">
          <div class="upload-file-actions">
            <a-icon
              v-if="
                ['jpg', 'jpeg', 'png'].includes(fileList[0].name.toLowerCase().split('.')[1])
              "
              type="eye"
              @click.stop="previewImg(fileList[0].url)"
            />
            <a-icon type="download" @click.stop="handleDownload(fileList[0])" />
            <a-icon v-if="showDel" type="delete" @click.stop="handleSingleDel(fileList[0])" />
          </div>
        </span>
      </div>
    </a-upload>
    <Modal :visible="previewVisible" :closable="true" overflow-auto :footer="''" :title="`查看大图`" :size="'normal'" :mask-closable="false" @cancel="previewVisible=false">
      <img alt="example" style="width: 100%" :src="previewImage">
    </Modal>
  </div>
</template>

<script>
import ImageCompressor from 'image-compressor.js' // 引入
import { upload, getSignature } from '@/services/upload/index.js'
import { IMG_PREVIEW } from '@/services/api.js'

/**
 * typeList<Array>
 */
export default {
  name: 'UploadImage',
  components: {
    Modal: () => import('@/components/CbModal/index.vue')
  },
  model: {
    prop: 'propFileList',
    event: 'change'
  },
  props: {
    // 文件类型列表
    typeList: {
      default: () => ['*'],
      type: Array
    },
    // 大小 单位M
    fileSize: {
      default: 2,
      type: Number
    },
    // 上传的文案
    uplaodTip: {
      default: '上传附件',
      type: String
    },
    // 最大上传数量
    maxSum: {
      default: 2,
      type: Number
    },
    // 文件是否压缩
    compress: {
      default: true,
      type: Boolean
    },
    // 文件列表
    propFileList: {
      type: Array,
      default: () => []
    },
    // 下面为业务属性
    // 文件类型,头像为avatar,其他文件为other
    fileType: {
      default: 'other',
      type: String,
      require: true
    },
    // 0 不敏感 1敏感
    sensitiveFile: {
      default: '1',
      type: String,
      require: true
    },
    disabled: {
      type: Boolean,
      default: false
    },
    showDel: {
      type: Boolean,
      default: true
    },
    modelUpload: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      basePath: process.env.VUE_APP_API_BASE_URL,
      imageUrl: '',
      fileList: [],
      loading: false,
      index: -1,
      previewVisible: false, // 预览弹窗显示
      previewImage: '' // 预览图片路径
    }
  },
  computed: {
    defaultIcon: function() {
      const name = this.fileList[0].name.toLowerCase().split('.')
      if (['doc', 'docx'].includes(name[1])) {
        return require('@/assets/img/word_icon.png')
      } else if (['pdf'].includes(name[1])) {
        return require('@/assets/img/pdf_icon.png')
      }
      return ''
    },
    imageIcon: function() {
      const name = this.fileList[0].name.toLowerCase().split('.')
      if (['jpg', 'jpeg', 'png'].includes(name[1])) {
        return this.fileList[0].fileUrl ? this.fileList[0].fileUrl : this.fileList[0].url
      }
      return ''
    }
  },
  watch: {
    fileList(newVal) {
      if (newVal.length > 0) {
        newVal[0].name = newVal[0].name ? newVal[0].name : '-'
        this.$emit('change', newVal)
      }
    },
    propFileList(newVal) {
      const targetVal = newVal.map((item, index) => {
        return {
          ...item,
          uid: index, // 注意，这个uid一定不能少，否则上传失败
          // name: item.fileName,
          status: 'done',
          url: item.fileUrl,
          percent: 100
        }
      })
      // 防止死循环
      if (newVal.length === 0 || newVal[newVal.length - 1].status) return
      this.$set(this, 'fileList', targetVal)
      if (this.fileList.length > 0) {
        this.index = 0
      }
    }
  },
  methods: {
    handleChange({ file, fileList }) {
      // 删除文件
      if (file.status && file.status === 'removed') {
        this.fileList = fileList
        this.index = fileList.length - 1
        this.$emit('fileDel', { file, fileList })
      }
    },
    // 上传前验证
    beforeUpload({ name, size }) {
      return new Promise((resolve, reject) => {
        // 判断类型
        const { typeList, fileSize } = this
        if (!typeList.includes('*')) {
          const singleType = name.toLowerCase().split('.')
          const typeInclude = typeList.includes(singleType[1])
          if (!typeInclude) {
            this.$message.error('上传文件格式错误, 请重新选择')
            return reject(false)
          }
        }
        // 判断大小
        const isLt2M = size / 1024 / 1024 < fileSize
        if (!isLt2M) {
          this.$message.error(`文件最大上传${fileSize}M`)
          return reject(false)
        }
        resolve(true)
      })
    },
    // 自定义上传的方法
    async customRequest(file) {
      // 设置文件的 realIndex 值
      // 该值用来计算 文件上传的相关信息
      const realIndex = ++this.index
      // 判断是否超出数量
      if (realIndex > this.maxSum - 1) {
        this.$message.error(`最多上传${this.maxSum}个`)
        this.index--
        return
      }
      const imgItem = {
        uid: realIndex, // 注意，这个uid一定不能少，否则上传失败
        name: '',
        status: 'uploading',
        url: '',
        percent: 0 // 注意不要写100。100表示上传完成
      }
      this.fileList.push(imgItem)
      const form = new FormData()
      const realindex = this.fileList.length - 1
      form.append('file', file.file)
      form.append('fileName', file.file.name)
      form.append('fileType', this.fileType)
      form.append('sensitiveFile', this.sensitiveFile)
      // 请求上传文件
      const res = await upload(form)
      if (res.code === 100) {
        let url = ''
        if (this.sensitiveFile === '1') {
          const signatureRes = await getSignature({
            id: res.data.fileId
          })
          if (signatureRes.code === 100) {
            url = `${IMG_PREVIEW}/img?id=${res.data.fileId}&signature=${signatureRes.data.signature}&expireTime=${signatureRes.data.time}`
          }
        } else {
          url = `${IMG_PREVIEW}/img?id=${res.data.fileId}`
        }
        const rimgItem = {
          ...imgItem
        }
        rimgItem.name = res.data.fileName
        rimgItem.originFileName = res.data.originFileName
        rimgItem.fileId = res.data.fileId
        rimgItem.staffFileId = res.data.staffFileId
        rimgItem.url = url
        rimgItem.status = 'done'
        // this.$set(this.fileList, (realindex), rimgItem)
        this.fileList.splice(realindex, 1, rimgItem)
        this.$emit('fileUpload', { file: form, fileList: this.fileList, fileId: res.data.fileId })
      }
    },
    // 压缩文件
    compressHandle(file) {
      return new Promise((resolve, reject) => {
        new ImageCompressor(file, {
          quality: 0.6,
          success(result) {
            return resolve(result)
          },
          error(e) {
            reject(e)
          }
        })
      })
    },
    // 单独预览
    previewImg(url) {
      this.previewImage = url
      this.previewVisible = true
    },
    // 单独删除
    handleSingleDel(file) {
      if (this.modelUpload) {
        this.$Reconfirm({
          title: '提示',
          content: `确认删除${file.name}吗？`
        }).then(() => {
          this.fileList = []
          this.index = this.fileList.length - 1
          this.$emit('fileDel', { file })
        }).catch(() => {
          console.log('cancel')
        })
      } else {
        this.fileList = []
        this.index = this.fileList.length - 1
        this.$emit('fileDel', { file })
      }
    },
    // 单独下载
    handleDownload(file) {
      window.location.href = `${this.basePath}/hrsaas-storage/file?id=${file.fileId}&signature=${file.signature}&expireTime=${file.time}`
    }
  }
}
</script>

<style lang="less" scoped>
.upload-image {
  position: relative;
  .upload-file-contain {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 1;
      top: 0;
      left: 0;
    .flex-center();
    flex-direction: column;
      padding: 8px;
    .upload-file {
      width: 34px !important;
      height: 39px !important;
    }
    .upload-file-image{
      max-width: calc(307px - 16px);
      // width: 100%;
      height: 100%;
    }
    .upload-title {
      .font-size(14px);
      color: @sc-grey-60;
      margin-top: 9px;
      max-width: 280px;
      .multi-ellipsis(1);
    }
    .upload-file-actions-p {
      position: absolute;
      z-index: 1;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(32, 42, 64, 0.5);
      opacity: 0;
      -webkit-transition: all 0.3s;
      transition: all 0.3s;
      .upload-file-actions {
        text-align: right;
        .anticon {
          font-size: 16px;
          vertical-align: bottom;
          vertical-align: -webkit-baseline-middle;
          color: #fff;
          padding: 0 6px;
        }
      }
    }
    &:hover .upload-file-actions-p,
    &:hover .upload-file-actions {
      opacity: 1;
    }
  }
  .ant-upload-select-picture-card i {
    font-size: 32px;
    color: #999;
  }

  .ant-upload-select-picture-card .ant-upload-text {
    // margin-top: 8px;
    color: #666;
  }
}

</style>
