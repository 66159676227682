var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "timeNode" }, [
    _c("div", { staticClass: "top" }, [
      _vm.hasStartLine
        ? _c("div", {
            staticClass: "startLine",
            style:
              "background-image: linear-gradient(to " +
              _vm.rowType +
              ", " +
              _vm.borderStyle +
              ");",
          })
        : _vm._e(),
      _c("div", { staticClass: "circle" }),
      _c("div", { staticClass: "time" }, [_vm._v(_vm._s(_vm.data.time))]),
      !(_vm.isLastRow && _vm.isLast && _vm.rowType === "right")
        ? _c("div", {
            staticClass: "line",
            class: { lastWidth: _vm.isLast },
            style:
              "background-image: linear-gradient(to " +
              _vm.rowType +
              ", " +
              _vm.borderStyle +
              ");",
          })
        : _vm._e(),
    ]),
    _c("div", { staticClass: "content" }, [
      _c("div", { staticClass: "title" }, [_vm._v(_vm._s(_vm.data.title))]),
      _c(
        "div",
        { staticClass: "reason" },
        [_vm._t("default", null, { row: _vm.data })],
        2
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }